<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Experiment 2: Pre-Lab Safety Questions</h2>

      <p class="mb-n3">a) Which of the following is <b>NOT</b> a physical property.</p>

      <v-radio-group
        v-model="inputs.physicalProperties"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsPhysicalProperties"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) Which of the following is a unique identification code for each chemical?
      </p>

      <v-radio-group
        v-model="inputs.identificationCodeLocation"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsIDcode"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        c) Whenever you are using an unknown chemical in the lab, you have to search for the
        chemical's _________________.
      </p>

      <v-radio-group v-model="inputs.chemicalNeedToKnows" class="mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsNeedToKnows"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) What does the abbreviation of SDS stand for?</p>

      <v-radio-group v-model="inputs.MSDS" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsMSDS"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'OleMissPrelab2Questions1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        physicalProperties: null,
        identificationCodeLocation: null,
        chemicalNeedToKnows: null,
        MSDS: null,
      },
      optionsPhysicalProperties: [
        {text: '$\\text{Color}$', value: 'color'},
        {text: '$\\text{Flammability}$', value: 'flammability'},
        {text: '$\\text{Smell}$', value: 'smell'},
        {text: '$\\text{Density}$', value: 'density'},
      ],
      optionsIDcode: [
        {text: '$\\text{SDS Number}$', value: 'SDS'},
        {text: '$\\text{CAS Number}$', value: 'CAS'},
        {text: '$\\text{NSNS Number}$', value: 'NSNS'},
      ],
      optionsNeedToKnows: [
        {text: '$\\text{clean-up practices only}$', value: 'I'},
        {text: '$\\text{safety precautions only}$', value: 'II'},
        {text: '$\\text{hazards only}$', value: 'III'},
        {text: '$\\text{clean-up practices, safety precautions, and hazards}$', value: 'allOthers'},
      ],
      optionsMSDS: [
        {text: 'Source Data Sheet', value: 'sourceDataSheet'},
        {text: 'Safety Data Sheet', value: 'safetyDataSheet'},
        {text: 'Species Data Source', value: 'speciesDataSource'},
        {text: 'Safety Data Source', value: 'safetyDataSource'},
      ],
    };
  },
};
</script>
<style scoped></style>
